const g=(s,i)=>{const d=document.querySelectorAll(".fusion-pdp__media-item"),f=document.getElementById("fusionMediaInnerWrapper"),l=document.querySelectorAll(".fusion-pdp__media-thumbnail"),h=document.querySelector(".fusion-pdp__media-thumbnails-container"),o=document.querySelectorAll(".fusion-pdp__media-item-desktop"),u=document.querySelector(".fusion-pdp__media-item-desktop-container");s.forEach((n,e)=>{var r,t,a,c,m;d[e]===void 0?f.insertAdjacentHTML("beforeend",`
                <div id="fusionMediaItem">
                    <div class="fusion-pdp__media-item media pdp-media-ratio">
                        <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${n.src}" src="${n.src}">
                    </div>
                </div>
            `):d[e].innerHTML=`
                ${e===0&&((r=i==null?void 0:i.product)!=null&&r.model_info)?`
                    <div class="pdp__model-info"><span>${(t=i==null?void 0:i.product)==null?void 0:t.model_info}</span></div>`:""}
                <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${n.src}" src="${n.src}">
            `,l[e]===void 0?h.insertAdjacentHTML("beforeend",`
                <div @click="setActiveSlide(${e})" class="fusion-pdp__media-thumbnail hs-carousel-pagination-item w-fit relative shrink-0 rounded-md sm:rounded-lg overflow-hidden cursor-pointer border-2 shadow-sm max-w-[50px]" :class="activeSlide === ${e} ? 'border-[#1f1f22]' : ''">
                    <img class="lazyload pointer-events-none bg-gray-100 w-fit size-full object-contain rounded-md sm:rounded-lg" src="${n.src}" alt="${(a=i==null?void 0:i.product)==null?void 0:a.title} Image ${e+1}">
                </div>
            `):l[e].innerHTML=`
                <img class="lazyload pointer-events-none bg-gray-100 w-fit size-full object-contain rounded-md sm:rounded-lg" src="${n.src}" alt="${(c=i==null?void 0:i.product)==null?void 0:c.title} Image ${e+1}">
            `,o[e]===void 0?u.insertAdjacentHTML("beforeend",`
            <div x-show="activeSlide === ${e}" class="fusion-pdp__media-item-desktop bg-gray-100 hs-carousel-slide rounded-lg shadow-sm border border-gray-100">
                <img class="lazyload pointer-events-none size-full object-contain rounded-lg" src="${n.src}" alt="${(m=i==null?void 0:i.product)==null?void 0:m.title} Image ${e+1}">
            </div>
            `):o[e].innerHTML=`
                <img class="lazyload" loading="lazy" height="1400" width="1050" data-src="${n.src}" src="${n.src}">
            `}),d.forEach((n,e)=>{e>=s.length&&n.closest&&n.closest("#fusionMediaItem").remove()}),o.forEach((n,e)=>{e>=s.length&&n.remove()}),l.forEach((n,e)=>{e>=s.length&&n.remove()}),window.innerWidth<=900&&window.fusionDataLayer.activeSlider&&window.fusionElements.pdpMobileSlider.update(),window.resetActiveSlide()};export{g as u};
